<template>
  <div class="qlPagination">
    <el-pagination
      v-on="$listeners"
      v-bind="$attrs"
      :hide-on-single-page="isHide"
      :page-sizes="[5, 10, 15, 20]"
      :total="total"
      :layout="layout"
      background
    >
      <span class="qlPagination_total">共 {{ total }} {{ unit }} </span>
    </el-pagination>

    <div class="flex-1 self-center pd-l-16" v-if="!isHide">
      <slot name="left"></slot>
    </div>
  </div>
</template>

<script>
import { Pagination } from 'element-ui';

export default {
  name: 'QlPagination',
  props: {
    unit: {
      type: String,
      default: '项',
    },
    total: {
      type: Number,
    },
    layout: {
      type: String,
      default: 'slot, prev, pager, next, sizes',
    },
  },
  data() {
    return {
      // pageSize: 10,
    };
  },
  computed: {
    isHide() {
      if (this.total === 0 || this.total) {
        return this.total < 5;
      }
      return false;
    },
  },
  components: {
    [Pagination.name]: Pagination,
  },
};
</script>

<style lang="scss" scoped>
.qlPagination {
  display: flex;
  flex-direction: row-reverse;
  padding: 32px 0;

  .qlPagination_total {
    display: inline-block;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    vertical-align: top;
    box-sizing: border-box;
    color: #808080;
    margin-right: 8px;
    font-weight: 400;
  }

  &::v-deep {
    .el-pagination__total {
      color: #6d7171;
    }

    .el-pagination.is-background {
      .el-pager li:not(.disabled).active {
        border: none;
      }

      .el-pagination__sizes {
        height: 30px;
        line-height: 30px;
      }
    }

    .btn-next,
    .btn-prev,
    .el-pager li {
      background: #ffffff;
      border: 1px solid #dee0e3;
      color: #303333;
      height: 30px;
      line-height: 30px;
      margin: 0 4px;
      font-size: 14px;
    }

    .btn-next,
    .btn-prev {
      color: #808080;

      .el-icon {
        font-size: 16px;
      }

      &:disabled {
        background: #f1f1f1;
        color: #808080;
      }
    }

    .el-input,
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
